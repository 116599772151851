var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("div", [_c("upgradeTest")], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }